import * as React from "react";
import { Box, Grid, Typography, Container } from "@mui/material";

const Footer = () => {
  return (
    <Box
      sx={{
        bgcolor: "background.paper",
        color: "text.secondary",
        borderColor: "divider",
      }}
      style={{ backgroundColor: "#1c1c1c" }}
    >
      <Container maxWidth="xl">
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          sx={{ mx: 2 }}
        ></Grid>
        <Typography
          variant="body2"
          color="text.secondary"
          align="center"
          sx={{ pt: 4 }}
        >
          © 2024 BRC20 Scout All Rights Reserved. Terms of Service | Privacy
          Policy
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;

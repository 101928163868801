import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Button, Container } from "@mui/material";

export default function Header() {
  return (
    <Box
      sx={{
        flexGrow: 1,
        borderColor: "grey.500",
      }}
    >
      <AppBar position="static" style={{ backgroundColor: "#1c1c1c" }}>
        <Container maxWidth="xl">
          <Toolbar>
            <Typography
              variant="h6"
              noWrap
              component={Link}
              to="/"
              sx={{
                ":hover": {
                  color: "grey",
                  boxShadow: "10px",
                  fontWeight: "bold",
                },
                transition: ".25s",
                cursor: "pointer",
                flexGrow: 1,
                textDecoration: "none",
                color: "white",
                display: { xs: "none", sm: "block" },
              }}
            >
              BRC20 SCOUT
            </Typography>

            <Box sx={{ display: { xs: "none", sm: "block" }, mr: 2 }}>
              <Button sx={{ color: "#fff" }}>Home</Button>
            </Box>
            <Box sx={{ display: { xs: "none", sm: "block" }, mr: 2 }}>
              <Button sx={{ color: "#fff" }}>Exchanges</Button>
            </Box>
            <Box sx={{ display: { xs: "none", sm: "block" }, mr: 2 }}>
              <Button sx={{ color: "#fff" }}>Tutorials</Button>
            </Box>
            <Box sx={{ display: { xs: "none", sm: "block" }, mr: 2 }}>
              <Button sx={{ color: "#fff" }}>Advertise</Button>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
}

import React, { memo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Skeleton, TableBody, TableCell, TableRow, Box } from "@mui/material";
import { useCoinMarket } from "../../apis/hooks-helpers";
import BodyRow from "./BodyRow";

const BodySkeleton = ({ rows, heads }) => {
  const rowArray = Array(rows).fill(null);
  const cellArray = Array(heads).fill(null);
  return rowArray.map((_, index) => (
    <TableRow key={index}>
      {cellArray.map((_, index) => (
        <TableCell key={index} align={index === 1 ? "left" : "right"}>
          {index === 1 ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Skeleton
                variant="circular"
                width={25}
                height={25}
                sx={{ mr: 1 }}
              />
              <Skeleton width={100} />
            </Box>
          ) : (
            <Skeleton />
          )}
        </TableCell>
      ))}
    </TableRow>
  ));
};

const CoinTableBody = memo(({ setDataLength }) => {
  const { data, isLoading } = useCoinMarket();
  const navigate = useNavigate(); // Using useNavigate hook instead of useHistory

  useEffect(() => {
    setDataLength(data.length);
  }, [data.length]);

  const handleRowClick = (coinSymbol) => {
    navigate(`/coin/brc20/${coinSymbol}`); // Using navigate function to navigate to the specified URL
  };

  return (
    <TableBody>
      {isLoading ? (
        <BodySkeleton heads={8} />
      ) : (
        data.map((row, index) => (
          <BodyRow
            key={row.id}
            row={row}
            index={index + 1}
            onClick={() => handleRowClick(row.symbol)}
          />
        ))
      )}
    </TableBody>
  );
});
export default CoinTableBody;

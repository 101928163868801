import { createTheme, ThemeProvider } from "@mui/material";
import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import Home from "./pages/Home";
import CoinDetail from "./pages/CoinDetail";
import AddCoin from "./pages/AddCoin";

let theme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#222831", // Change default background color here
    },
  },
});

// If you want to make the font sizes responsive, you can use responsiveFontSizes function like this:
// theme = responsiveFontSizes(theme);

function App() {
  useEffect(() => {
    document.body.style.margin = 0;
    // Add any other style changes you want to make to the body here
    return () => {
      // Clean up the changes when the component unmounts
      document.body.style.margin = 0; // Restore default background color
    };
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/coin/brc20/:coinSymbol" element={<CoinDetail />} />
        <Route path="/admin" element={<AddCoin />} />
      </Routes>
      <Footer />
    </ThemeProvider>
  );
}

export default App;

import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

export default function RecentlyAddedCoins({ title, value }) {
  return (
    <Card
      sx={{
        minWidth: 275,
        height: 150,
        backgroundColor: "#293143",
        borderRadius: "10px",
        boxShadow: 10,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#343e56",
          p: 1,
        }}
      >
        <Typography color="text.primary" sx={{ display: "flex" }}>
          {title}&nbsp;
        </Typography>
      </Box>
      <CardContent>
        {value.map((item, index) => (
          <Box
            sx={{
              display: "flex",
              justifyContent: "left",
            }}
          >
            <Typography
              key={index}
              color="text.primary"
              sx={{ padding: "0 20px" }}
            >
              {item.name}
            </Typography>
            <Typography key={index} color="text.primary">
              {item.symbol}
            </Typography>
          </Box>
        ))}
      </CardContent>
    </Card>
  );
}

import { useState } from "react";
import Table from "@mui/material/Table";
import {
  Paper,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Container,
  Box,
  Grid,
} from "@mui/material";

import { useCoinMarket, numberFormat } from "../../apis/hooks-helpers";

import CoinTableBody from "./CoinTableBody";
import BasicCard from "../BasicCard";
import Top3GainersCard from "../Top3GainersCard";
import RecentlyAddedCoins from "../RecentlyAddedCoins";

const CoinTable = () => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [dataLength, setDataLength] = useState(0);
  const { totalMarketCap, top3Gainers, recentlyAddedCoins } = useCoinMarket();
  console.log("recent", recentlyAddedCoins);

  return (
    <Paper style={{ backgroundColor: "#222831", borderRadius: 0 }}>
      <Container maxWidth="xl">
        <Grid
          container
          justifyContent="space-between"
          sx={{ paddingTop: "30px" }}
        >
          <Box sx={{ m: 2 }}>
            <Grid item xs={6} sm={3} md={2}>
              <BasicCard
                title="Total marketcap"
                value={`${numberFormat(totalMarketCap)}`}
              />
            </Grid>
          </Box>
          <Box sx={{ m: 2 }}>
            <Grid item xs={6} sm={3} md={2}>
              <Top3GainersCard title="Top 3 Gainers" value={top3Gainers} />
            </Grid>
          </Box>
          <Box sx={{ m: 2 }}>
            <Grid item xs={6} sm={3} md={2}>
              <RecentlyAddedCoins
                title="Recently added"
                value={recentlyAddedCoins}
              />
            </Grid>
          </Box>
          <Box sx={{ m: 2 }}>
            <Grid item xs={6} sm={3} md={2}>
              <BasicCard title="Advertise link" />
            </Grid>
          </Box>
        </Grid>
        <Box sx={{ m: 2 }}>
          <TableContainer>
            <Table
              sx={{
                minWidth: 700,
                borderRadius: "10px",
                "& td": { fontWeight: 600 },
                backgroundColor: "#2d4059",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>name</TableCell>
                  <TableCell align="right">Price</TableCell>
                  <TableCell align="right">24h %</TableCell>
                  <TableCell align="right">7d %</TableCell>
                  <TableCell align="right">Market Cap</TableCell>
                  <TableCell align="right">Volume(24h)</TableCell>
                  <TableCell align="center">Last&nbsp;7&nbsp;days</TableCell>
                </TableRow>
              </TableHead>
              <CoinTableBody setDataLength={setDataLength} />
            </Table>
          </TableContainer>
          <TablePagination
            component={"div"}
            rowsPerPageOptions={[5, 10, 20]}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={(e) => {
              setRowsPerPage(parseInt(e.target.value));
              setPage(0);
            }}
            count={dataLength}
            page={page}
            onPageChange={(e, newPage) => {
              setPage(newPage);
            }}
          />
        </Box>
      </Container>
    </Paper>
  );
};

export default CoinTable;

import { useState, useEffect } from "react";

function useCoinMarket() {
  const [state, setState] = useState({
    data: [],
    totalMarketCap: 0,
    top3Gainers: [],
    recentlyAddedCoins: [],
    newCoins: [],
    isLoading: true,
  });

  const updateState = (
    data,
    totalMarketCap,
    top3Gainers,
    recentlyAddedCoins,
    newCoins
  ) => {
    setState({
      data: data,
      totalMarketCap: totalMarketCap,
      top3Gainers: top3Gainers,
      recentlyAddedCoins: recentlyAddedCoins,
      newCoins: newCoins,
      isLoading: false,
    });
  };

  useEffect(() => {
    async function init() {
      try {
        const res = await fetch("/api");
        const {
          data,
          totalMarketCap,
          top3Gainers,
          recentlyAddedCoins,
          newCoins,
        } = await res.json();
        updateState(
          data,
          totalMarketCap,
          top3Gainers,
          recentlyAddedCoins,
          newCoins
        );
      } catch (err) {
        console.log(err);
      }
    }

    init();

    const id = setInterval(() => {
      init();
    }, 1 * 60 * 1000);

    return () => clearInterval(id);
  }, []);

  return state;
}

function useCoinDetailInfo(coinSymbol) {
  const [state, setState] = useState({
    data: [],
    isLoading: true,
  });

  const updateState = (data) => {
    setState({
      data: data,
      isLoading: false,
    });
  };

  useEffect(() => {
    async function init() {
      try {
        const res = await fetch(`/api/brc20/${coinSymbol}`); // Updated URL with coinId
        const { data } = await res.json();
        updateState(data);
      } catch (err) {
        console.log(err);
      }
    }

    init();

    const id = setInterval(() => {
      init();
    }, 1 * 60 * 1000);

    return () => clearInterval(id);
  }, [coinSymbol]); // Depend on coinId to trigger useEffect when it changes

  return state;
}

function numberFormat(num, options) {
  let temp = 2;
  if (num < 1 && num > 0.0001) {
    temp = 4;
  }
  if (num < 0.0001) {
    temp = 8;
  }
  let defaultOptions = {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: temp,
    minimumFractionDigits: 2,
    notation: "standard",
    compactDisplay: "long",
  };
  return new Intl.NumberFormat("en-US", {
    ...defaultOptions,
    ...options,
  }).format(num);
}

function numberOnlyFormat(num, options) {
  let temp = 2;
  if (num < 1 && num > 0.0001) {
    temp = 4;
  }
  if (num < 0.0001) {
    temp = 8;
  }
  let defaultOptions = {
    maximumFractionDigits: temp,
    minimumFractionDigits: 2,
  };
  return new Intl.NumberFormat("en-US", {
    ...defaultOptions,
    ...options,
  }).format(num);
}

export { useCoinMarket, numberFormat, useCoinDetailInfo, numberOnlyFormat };

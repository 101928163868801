import React from "react";
import { VictoryLine } from "victory";

const LineChart = ({ data }) => {
  const formattedData = data.map((value, index) => ({
    x: `Data ${index + 1}`,
    y: value,
  }));

  return (
    <VictoryLine
      data={formattedData}
      style={{
        data: { stroke: "white" }, // Set the line color here
      }}
      animate={{
        duration: 2000,
        onLoad: { duration: 1000 },
      }}
    />
  );
};

export default LineChart;

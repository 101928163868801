import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

export default function BasicCard({ title, value }) {
  return (
    <Card
      sx={{
        minWidth: 275,
        height: 150,
        backgroundColor: "#293143",
        borderRadius: "10px",
        boxShadow: 10,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#343e56",
          p: 1,
        }}
      >
        <Typography color="text.primary" sx={{ display: "flex" }}>
          {title}&nbsp;
        </Typography>
      </Box>
      <CardContent>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography color="text.secondary">Data</Typography>
          <Typography color="text.primary">{value}</Typography>
        </Box>
      </CardContent>
    </Card>
  );
}

import React from "react";
import { SwitchTransition } from "react-transition-group";
import { Fade, TableCell, TableRow, Box, Avatar } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { numberFormat } from "../../apis/hooks-helpers";
import LineChart from "../LineChart";

const BodyRow = ({ row, index, onClick }) => {
  const USD = row.quotes[0]?.quote.USD;
  const price = numberFormat(USD.price, "currency");
  const percent_24 = USD.percent_change_24h.toFixed(2);
  const percent_7d = USD.percent_change_7d.toFixed(2);
  const priceHistory = row.priceHistory;
  const volume_24 = numberFormat(USD.volume_24h);
  const renderPercentage = (num) => {
    return num > 0 ? (
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        color={"success.main"}
      >
        <ArrowDropUpIcon color={"success"} />
        <span>{num}%</span>
      </Box>
    ) : (
      <Box
        display={"flex"}
        justifyContent="flex-end"
        alignItems="center"
        color={"error.main"}
      >
        <ArrowDropDownIcon />
        <span> {num.replace("-", "")}%</span>
      </Box>
    );
  };

  return (
    <TableRow
      hover
      onClick={onClick}
      sx={{
        ":hover": {
          backgroundColor: "#f0f0f0ff",
        },
        backgroundColor: "#00000000",
        transition: ".25s",
        cursor: "pointer", // Change cursor to pointer
        "& td": { width: 20 },
      }}
    >
      <TableCell
        sx={(theme) => ({
          [theme.breakpoints.down("md")]: {
            position: "sticky",
            left: 0,
            zIndex: 10,
            backgroundColor: "#121212",
          },
        })}
      >
        {index}
      </TableCell>
      <TableCell
        padding="none"
        sx={(theme) => ({
          [theme.breakpoints.down("md")]: {
            position: "sticky",
            left: 48,
            zIndex: 10,
            backgroundColor: "#121212",
          },
        })}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Avatar
            src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${row.id}.png`}
            sx={{
              width: 25,
              height: 25,
              mr: 1,
            }}
          />
          <span>
            &nbsp;{row.symbol}&nbsp;
            <span style={{ fontSize: "11px" }}>{row.name}</span>
          </span>
        </Box>
      </TableCell>
      <SwitchTransition>
        <Fade key={price}>
          <TableCell align="right">{price}</TableCell>
        </Fade>
      </SwitchTransition>
      <SwitchTransition>
        <Fade key={percent_24}>
          <TableCell align="right">{renderPercentage(percent_24)}</TableCell>
        </Fade>
      </SwitchTransition>
      <SwitchTransition>
        <Fade key={percent_7d}>
          <TableCell align="right">{renderPercentage(percent_7d)}</TableCell>
        </Fade>
      </SwitchTransition>
      <TableCell align="right">{numberFormat(USD.market_cap)}</TableCell>

      <TableCell align="right">{volume_24}</TableCell>
      <TableCell align="right">
        <LineChart data={priceHistory} />
      </TableCell>
    </TableRow>
  );
};

export default BodyRow;

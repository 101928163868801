import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { numberOnlyFormat } from "../apis/hooks-helpers";

const LineChart = ({ dateOnly, priceData }) => {
  const [chartData, setChartData] = useState({
    options: {
      chart: {
        id: "line-chart",
        background: "#1a1a1a", // Set chart background color for dark theme
        foreColor: "#f0f0f0", // Set text color for dark theme
      },
      xaxis: {
        type: "datetime",
        labels: {
          style: {
            colors: "#f0f0f0", // Set label text color for dark theme
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#f0f0f0", // Set label text color for dark theme
          },
        },
      },
      theme: {
        mode: "dark", // Set theme mode to 'dark'
      },
      toolbar: {
        show: false, // Disable toolbar icons
      },
    },
    series: [
      {
        name: "Series 1",
        data: [],
      },
    ],
  });

  useEffect(() => {
    // Mock data generation (replace with your data fetching logic)
    const generateData = () => {
      let seriesData = [];
      let currentDate = new Date(dateOnly).getTime();
      for (let i = 0; i < priceData[0]?.length; i++) {
        let price = priceData[0]?.[i]?.quote?.USD?.price;
        price = numberOnlyFormat(price, "currency");
        seriesData.push([currentDate, price || 0]);
        currentDate -= 24 * 60 * 60 * 1000; // Increment by one day
      }
      console.log("series", seriesData);
      return seriesData;
    };

    // Update chart data with generated data
    setChartData((prevState) => ({
      ...prevState,
      series: [{ ...prevState.series[0], data: generateData() }],
    }));
  }, [dateOnly, priceData]); // Empty dependency array to run only once on component mount

  return (
    <div className="line-chart">
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="line"
        height={400}
      />
    </div>
  );
};

export default LineChart;

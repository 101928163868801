import { Box, Container, Grid, Paper, Switch, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import LineChart from "../components/LineDataSet";
import { useParams } from "react-router-dom";
import { useCoinDetailInfo, numberFormat } from "../apis/hooks-helpers";

const CoinDetail = () => {
  let { coinSymbol } = useParams();
  const coinDetail = useCoinDetailInfo(coinSymbol).data;
  console.log("coindetail", coinDetail);
  const lastItem = coinDetail[0]?.length;
  const dateOnly = coinDetail[0]?.[lastItem - 1]?.timestamp.split("T")[0];
  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 38,
    height: 22,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 18,
      height: 18,
    },
    "& .MuiSwitch-track": {
      borderRadius: 18 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  return (
    <Paper
      sx={{
        backgroundColor: "#222831",
        paddingBottom: "300px",
        paddingTop: "56px",
      }}
    >
      <Container maxWidth="xl" sx={{ p: 2 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <Typography color="text.primary">
              {coinDetail[2]}&nbsp;{coinDetail[3]}
            </Typography>
          </Box>
        </Box>
        <div style={{ display: "flex" }}>
          <Box sx={{ width: "33.333333%", p: 2 }}>
            <Box
              sx={{
                borderBottom: "1px solid #ccc",
                pb: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  p: 0.5,
                }}
              >
                <Typography color="text.secondary">
                  Circulating Supply
                </Typography>
                <Typography color="text.primary">
                  {
                    coinDetail[0]?.[lastItem - 1]?.quote?.USD
                      ?.circulating_supply
                  }
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  p: 0.5,
                }}
              >
                <Typography color="text.secondary">Total Supply</Typography>
                <Typography color="text.primary">
                  {coinDetail[0]?.[lastItem - 1]?.quote?.USD?.total_supply}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                borderBottom: "1px solid #ccc",
                pb: 2,
                pt: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  p: 0.5,
                }}
              >
                <Typography color="text.secondary">Price</Typography>
                <Typography color="text.primary">
                  {numberFormat(
                    coinDetail[0]?.[lastItem - 1]?.quote?.USD?.price
                  )}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  p: 0.5,
                }}
              >
                <Typography color="text.secondary">Full Market Cap</Typography>
                <Typography color="text.primary">
                  {numberFormat(
                    coinDetail[0]?.[lastItem - 1]?.quote?.USD?.market_cap
                  )}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                borderBottom: "1px solid #ccc",
                pb: 2,
                pt: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  p: 0.5,
                }}
              >
                <Typography color="text.secondary">24h Volume</Typography>
                <Typography color="text.primary">
                  {numberFormat(
                    coinDetail[0]?.[lastItem - 1]?.quote?.USD?.volume_24h
                  )}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  p: 0.5,
                }}
              >
                <Typography color="text.secondary">
                  Volume/Market cap (24h)
                </Typography>
                <Typography color="text.primary">
                  {coinDetail[0]?.[
                    lastItem - 1
                  ]?.quote?.USD?.percent_change_24h.toFixed(2)}
                  %
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ m: 2, width: "66.666666%" }}>
            <LineChart dateOnly={dateOnly} priceData={coinDetail} />
          </Box>
        </div>
      </Container>
    </Paper>
  );
};

export default CoinDetail;

import React, { useState, useEffect } from "react";
import { Container, Paper, Button } from "@mui/material";
import Input from "@mui/material/Input";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useCoinMarket } from "./../apis/hooks-helpers";

const AddCoin = () => {
  const [inputValue, setInputValue] = useState("");
  const [password, setPassword] = useState(""); // State to store entered password
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [coins, setCoins] = useState([]); // Define state for coins
  const { newCoins, isLoading } = useCoinMarket();
  const [authenticated, setAuthenticated] = useState(false); // State to track authentication
  console.log("auth", authenticated);

  useEffect(() => {
    // Check if the URL contains the correct password
    const urlParams = new URLSearchParams(window.location.search);
    const passwordParam = urlParams.get("password");

    if (passwordParam === "1r!R2t@T3y#Y") {
      console.log("Correct password entered");
      setAuthenticated(true);
    }
  }, []);

  useEffect(() => {
    if (!isLoading && authenticated) {
      fetchCoins();
    }
  }, [isLoading, authenticated]);

  const fetchCoins = async () => {
    try {
      const res = await fetch("/api");
      if (!res.ok) {
        throw new Error("Failed to fetch coins");
      }
      const { newCoins: fetchedCoins } = await res.json();
      setCoins(fetchedCoins); // Update state with the fetched coins
      setOpenSnackbar(true);
      setSnackbarMessage("Coins fetched successfully");
    } catch (error) {
      console.error("Error fetching coins:", error);
      setOpenSnackbar(true);
      setSnackbarMessage("Failed to fetch coins");
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleAddButtonClick = () => {
    fetch(`/api/add-coin/${inputValue}`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log(data); // assuming the response contains { data: "Success" }
        if (data.data === "fail_already") {
          console.log("asdgasdgasdg");
          handleSnackbarOpen("This Coin is already exist!");
        } else if (data.data === "success") {
          handleSnackbarOpen("Coin added successfully");
          fetchCoins();
        } else if (data.data === "fail_all") {
          handleSnackbarOpen("This coin doesn't exist in the world!");
        }
      })
      .catch((error) => {
        console.error("There was a problem with your fetch operation:", error);
      });
  };

  const handleDeleteButtonClick = () => {
    fetch(`/api/delete-coin/${inputValue}`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log(data); // assuming the response contains { data: "Success" }
        if (data.data === "success") {
          handleSnackbarOpen("Coin deleted successfully");
          fetchCoins();
        } else if (data.data === "fail") {
          handleSnackbarOpen("This coin does not exist, please choose again!");
        }
      })
      .catch((error) => {
        console.error("There was a problem with your fetch operation:", error);
      });
  };

  const handleSnackbarOpen = (message) => {
    setSnackbarMessage(message);
    setOpenSnackbar(true);
  };

  return (
    <Paper
      sx={{
        backgroundColor: "#222831",
        paddingBottom: "300px",
        paddingTop: "56px",
      }}
    >
      <Container maxWidth="xl" sx={{ p: 2 }}>
        {authenticated ? (
          <div style={{ display: "flex" }}>
            <div>
              <h1>Please Add New Coin's ID</h1>
              <Input value={inputValue} onChange={handleInputChange} />
              <br />
              <Button
                variant="outlined"
                style={{
                  marginTop: "20px",
                  marginRight: "10px",
                  width: "80px",
                }}
                onClick={handleAddButtonClick}
              >
                Add
              </Button>
              <Button
                variant="contained"
                style={{ marginTop: "20px", width: "80px" }}
                onClick={handleDeleteButtonClick}
              >
                Delete
              </Button>
            </div>
            <div style={{ paddingLeft: "100px" }}>
              <ul>
                {coins.map((row, index) => (
                  <li key={index}>
                    {row.id}
                    <span>
                      &nbsp;&nbsp;&nbsp;{row.name}&nbsp;&nbsp;&nbsp;{row.symbol}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <h1>Please Enter Password</h1>
            <Input
              type="password"
              value={password}
              onChange={handlePasswordChange}
              placeholder="Enter Password"
              style={{ marginBottom: "10px", marginRight: "10px" }}
            />
            <Button
              variant="contained"
              onClick={() => {
                // Check if the entered password matches the correct password
                if (password === "1r!R2t@T3y#Y") {
                  setAuthenticated(true);
                } else {
                  alert("Incorrect password. Please try again.");
                }
              }}
            >
              Submit
            </Button>
          </div>
        )}
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={() => setOpenSnackbar(false)}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={() => setOpenSnackbar(false)}
            severity="success"
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
      </Container>
    </Paper>
  );
};

export default AddCoin;

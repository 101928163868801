import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const renderPercentage = (num) => {
  return num > 0 ? (
    <Box
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
      color={"success.main"}
    >
      <ArrowDropUpIcon color={"success"} />
      <span>{num}%</span>
    </Box>
  ) : (
    <Box
      display={"flex"}
      justifyContent="flex-end"
      alignItems="center"
      color={"error.main"}
    >
      <ArrowDropDownIcon />
      <span> {num.replace("-", "")}%</span>
    </Box>
  );
};

export default function Top3GainersCard({ title, value }) {
  return (
    <Card
      sx={{
        minWidth: 275,
        height: 150,
        backgroundColor: "#293143",
        borderRadius: "10px",
        boxShadow: 10,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#343e56",
          p: 1,
        }}
      >
        <Typography color="text.primary" sx={{ display: "flex" }}>
          {title}&nbsp;
        </Typography>
      </Box>
      <CardContent>
        {value.map((item, index) => (
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography key={index} color="text.primary">
              {item.symbol}
            </Typography>
            <Typography key={index} color="text.primary">
              {renderPercentage(item.percent_gain_24h)}
            </Typography>
          </Box>
        ))}
      </CardContent>
    </Card>
  );
}
